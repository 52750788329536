// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-recommended-js": () => import("./../../../src/pages/SearchRecommended.js" /* webpackChunkName: "component---src-pages-search-recommended-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-dealer-info-template-js": () => import("./../../../src/templates/DealerInfoTemplate.js" /* webpackChunkName: "component---src-templates-dealer-info-template-js" */),
  "component---src-templates-dealer-locator-js": () => import("./../../../src/templates/DealerLocator.js" /* webpackChunkName: "component---src-templates-dealer-locator-js" */),
  "component---src-templates-dealers-js": () => import("./../../../src/templates/dealers.js" /* webpackChunkName: "component---src-templates-dealers-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-sell-your-vehicle-js": () => import("./../../../src/templates/SellYourVehicle.js" /* webpackChunkName: "component---src-templates-sell-your-vehicle-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-vehicle-js": () => import("./../../../src/templates/Vehicle.js" /* webpackChunkName: "component---src-templates-vehicle-js" */)
}

